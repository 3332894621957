.page{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
}
video{
  max-width: 90vw;
}

img{
  position: fixed;
  bottom: 50px;
  right: 50px;
  max-width: 30vw;
}